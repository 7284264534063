@charset "UTF-8";
@font-face {
  font-family: 'Neutra Text';
  src: url("../../fonts/NeutraText-Bold.woff2") format("woff2"), url("../../fonts/NeutraText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neutra Text';
  src: url("../../fonts/NeutraText-Book.woff2") format("woff2"), url("../../fonts/NeutraText-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  max-width: 1200px;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.fluid {
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-x {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.cell {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
}

.cell.shrink {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}

.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.grid-x > .small-1 {
  width: 8.33333%;
}

.grid-x > .small-2 {
  width: 16.66667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.33333%;
}

.grid-x > .small-5 {
  width: 41.66667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.33333%;
}

.grid-x > .small-8 {
  width: 66.66667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.33333%;
}

.grid-x > .small-11 {
  width: 91.66667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .grid-x > .medium-shrink {
    width: auto;
  }
  .grid-x > .medium-1 {
    width: 8.33333%;
  }
  .grid-x > .medium-2 {
    width: 16.66667%;
  }
  .grid-x > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4 {
    width: 33.33333%;
  }
  .grid-x > .medium-5 {
    width: 41.66667%;
  }
  .grid-x > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7 {
    width: 58.33333%;
  }
  .grid-x > .medium-8 {
    width: 66.66667%;
  }
  .grid-x > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10 {
    width: 83.33333%;
  }
  .grid-x > .medium-11 {
    width: 91.66667%;
  }
  .grid-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .grid-x > .large-shrink {
    width: auto;
  }
  .grid-x > .large-1 {
    width: 8.33333%;
  }
  .grid-x > .large-2 {
    width: 16.66667%;
  }
  .grid-x > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4 {
    width: 33.33333%;
  }
  .grid-x > .large-5 {
    width: 41.66667%;
  }
  .grid-x > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7 {
    width: 58.33333%;
  }
  .grid-x > .large-8 {
    width: 66.66667%;
  }
  .grid-x > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10 {
    width: 83.33333%;
  }
  .grid-x > .large-11 {
    width: 91.66667%;
  }
  .grid-x > .large-12 {
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -10px;
  margin-left: -0.625rem;
  margin-right: -10px;
  margin-right: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: 10px;
  margin-left: 0.625rem;
  margin-right: 10px;
  margin-right: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}

.grid-margin-x > .auto {
  width: auto;
}

.grid-margin-x > .shrink {
  width: auto;
}

.grid-margin-x > .small-1 {
  width: calc(8.33333% - 1.25rem);
}

.grid-margin-x > .small-2 {
  width: calc(16.66667% - 1.25rem);
}

.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}

.grid-margin-x > .small-4 {
  width: calc(33.33333% - 1.25rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.66667% - 1.25rem);
}

.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}

.grid-margin-x > .small-7 {
  width: calc(58.33333% - 1.25rem);
}

.grid-margin-x > .small-8 {
  width: calc(66.66667% - 1.25rem);
}

.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}

.grid-margin-x > .small-10 {
  width: calc(83.33333% - 1.25rem);
}

.grid-margin-x > .small-11 {
  width: calc(91.66667% - 1.25rem);
}

.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.875rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.875rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.875rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.875rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.875rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.875rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.33333% - 1.875rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.66667% - 1.875rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.33333% - 1.875rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.66667% - 1.875rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.33333% - 1.875rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.66667% - 1.875rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.33333% - 1.875rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.66667% - 1.875rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.33333% - 1.875rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.66667% - 1.875rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.33333% - 1.875rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.66667% - 1.875rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -10px;
  margin-right: -0.625rem;
  margin-left: -10px;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -10px;
  margin-right: -0.625rem;
  margin-left: -10px;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.grid-padding-x > .cell {
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.33333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.66667%;
}

.small-up-7 > .cell {
  width: 14.28571%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.33333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.66667%;
  }
  .medium-up-7 > .cell {
    width: 14.28571%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.33333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.66667%;
  }
  .large-up-7 > .cell {
    width: 14.28571%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.875rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem);
  }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem);
  }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem);
  }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .small-1 {
  width: 8.33333%;
}

.small-margin-collapse > .small-2 {
  width: 16.66667%;
}

.small-margin-collapse > .small-3 {
  width: 25%;
}

.small-margin-collapse > .small-4 {
  width: 33.33333%;
}

.small-margin-collapse > .small-5 {
  width: 41.66667%;
}

.small-margin-collapse > .small-6 {
  width: 50%;
}

.small-margin-collapse > .small-7 {
  width: 58.33333%;
}

.small-margin-collapse > .small-8 {
  width: 66.66667%;
}

.small-margin-collapse > .small-9 {
  width: 75%;
}

.small-margin-collapse > .small-10 {
  width: 83.33333%;
}

.small-margin-collapse > .small-11 {
  width: 91.66667%;
}

.small-margin-collapse > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: 10px;
  margin-left: 0.625rem;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem);
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem);
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem);
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem);
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem);
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem);
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem);
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: 0.9375rem;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem);
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem);
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem);
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem);
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem);
  }
}

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: 0.9375rem;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem);
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem);
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem);
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem);
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem);
  }
}

.grid-y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.grid-y > .cell {
  height: auto;
  max-height: none;
}

.grid-y > .auto {
  height: auto;
}

.grid-y > .shrink {
  height: auto;
}

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

.grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.grid-y > .small-1 {
  height: 8.33333%;
}

.grid-y > .small-2 {
  height: 16.66667%;
}

.grid-y > .small-3 {
  height: 25%;
}

.grid-y > .small-4 {
  height: 33.33333%;
}

.grid-y > .small-5 {
  height: 41.66667%;
}

.grid-y > .small-6 {
  height: 50%;
}

.grid-y > .small-7 {
  height: 58.33333%;
}

.grid-y > .small-8 {
  height: 66.66667%;
}

.grid-y > .small-9 {
  height: 75%;
}

.grid-y > .small-10 {
  height: 83.33333%;
}

.grid-y > .small-11 {
  height: 91.66667%;
}

.grid-y > .small-12 {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.33333%;
  }
  .grid-y > .large-2 {
    height: 16.66667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.33333%;
  }
  .grid-y > .large-5 {
    height: 41.66667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.33333%;
  }
  .grid-y > .large-8 {
    height: 66.66667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.33333%;
  }
  .grid-y > .large-11 {
    height: 91.66667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -10px;
  margin-top: -0.625rem;
  margin-bottom: -10px;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-padding-y > .cell {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.grid-margin-y {
  margin-top: -10px;
  margin-top: -0.625rem;
  margin-bottom: -10px;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 1.25rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 1.25rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 1.25rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 1.25rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 1.25rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 1.25rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 1.25rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 1.25rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 100%;
}

.cell-block-container > .grid-x {
  max-height: 100%;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100vh;
}

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    height: 100vh;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -10px;
  margin-top: -0.625rem;
  margin-bottom: -10px;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 1.25rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 1.25rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 1.25rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 1.25rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 1.25rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 1.25rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 1.25rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 1.25rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@media print, screen and (min-width: 64em) {
  html, body {
    height: 100%;
  }
}

body {
  font-family: "Neutra Text", sans-serif;
  font-size: 14px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin-bottom: 0 !important;
}

@media print, screen and (min-width: 64em) {
  body {
    background: #1d1d1b;
  }
}

@media screen and (min-width: 80em) {
  body {
    font-size: 15px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

picture {
  display: block;
  overflow: hidden;
}

picture img {
  display: inline-block;
  margin: auto;
  width: 100%;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="10" /></filter></svg>#filter');
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  -webkit-transition: -webkit-transform 0.15s, -webkit-filter 0.15s;
  transition: -webkit-transform 0.15s, -webkit-filter 0.15s;
  transition: transform 0.15s, filter 0.15s;
  transition: transform 0.15s, filter 0.15s, -webkit-transform 0.15s, -webkit-filter 0.15s;
}

picture img.lazyloaded {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
  -webkit-filter: blur(0px);
          filter: blur(0px);
  -webkit-transform: scale(1);
          transform: scale(1);
}

.menu-local-tasks ul li:first-child {
  display: none;
}

.menu-local-tasks ul li {
  margin-bottom: 10px;
}

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #1d1d1b;
  z-index: 99;
  background: #2c2929;
  border-radius: 0 3px 0 0;
}

.tabs li {
  display: inline;
}

.tabs a {
  display: inline-block;
  padding: 8px 12px 6px;
  font-size: 12px;
  color: #1d1d1b;
  text-decoration: none;
}

.tabs a:hover, .tabs a.active {
  border-bottom: 2px solid #1d1d1b;
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

.contentinfo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #1d1d1b;
  z-index: 99;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  max-width: 500px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5em;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

@media print, screen and (min-width: 64em) {
  .contentinfo {
    padding: 20px 50px;
    bottom: 20px;
    font-size: 15px;
  }
}

.contentinfo--error {
  background: #de3b2f;
}

.contentinfo--hidden {
  opacity: 0;
  pointer-events: none;
}

.contentinfo:after {
  content: '✕';
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, .2);
  width: 22px;
  height: 22px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #fff;
}

.contentinfo a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.form-item-view-mode {
  display: none !important;
}

a#edit-backlink, .menu-local-tasks ul li a {
  display: none;
}

@media print, screen and (min-width: 64em) {
  a#edit-backlink, .menu-local-tasks ul li a {
    display: inline-block;
    background: #041bb9;
    padding: 10px 25px;
    border-radius: 22px;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  a#edit-backlink:hover, .menu-local-tasks ul li a:hover {
    background: #031487;
  }
}

#toolbar-administration {
  display: none;
}

body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
  margin-left: 0 !important;
}

@media print, screen and (min-width: 64em) {
  body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
    margin-left: 240px !important;
    margin-left: 15rem !important;
  }
}

.adminimal-admin-toolbar nav.menu-local-tasks {
  position: fixed;
  z-index: 499;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 10%;
  padding: 10px;
  bottom: 0;
}

.a-wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media print, screen and (min-width: 35.5em) {
  .a-wrapper {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 48em) {
  .a-wrapper {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .a-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.a-wrapper--fat {
  max-width: 1680px;
}

.a-heading {
  line-height: 1.3em;
  font-weight: 700;
  color: #1d1d1b;
}

.a-heading--1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1.1em;
}

@media print, screen and (min-width: 64em) {
  .a-heading--1 {
    font-size: 30px;
  }
}

.a-heading--2 {
  font-size: 17px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .a-heading--2 {
    font-size: 18px;
  }
}

.a-heading--3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.4em;
}

@media print, screen and (min-width: 64em) {
  .a-heading--3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.a-heading--4 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 64em) {
  .a-heading--4 {
    font-size: 18px;
  }
}

.a-heading--white {
  color: #fff;
}

.a-heading--primary {
  color: #1d1d1b;
}

.a-heading--secondary {
  color: #2c2929;
}

.a-heading--no-top {
  margin-top: 0;
}

.a-heading--no-bot {
  margin-bottom: 0;
}

.a-heading--centered {
  text-align: center;
}

.a-heading--slim {
  font-weight: 400;
}

.a-heading__sub {
  display: block;
}

.a-text {
  line-height: 1.4em;
}

.a-text--white {
  color: #fff;
}

.a-text--primary {
  color: #1d1d1b;
}

.a-text--secondary {
  color: #2c2929;
}

.a-text--color-1 {
  color: #80bc00;
}

.a-text--color-2 {
  color: #7c9ac0;
}

.a-text--color-3 {
  color: #ff595a;
}

.a-text--color-4 {
  color: #3f2b56;
}

.a-text--fat {
  font-size: 15px;
  font-weight: 600;
}

@media screen and (min-width: 80em) {
  .a-text--fat {
    font-size: 18px;
  }
}

.a-text--slim {
  font-size: 12px;
}

@media print, screen and (min-width: 64em) {
  .a-text--slim {
    font-size: 14px;
  }
}

.a-text--centered {
  text-align: center;
}

.a-text__slim {
  font-size: 12px;
}

@media print, screen and (min-width: 64em) {
  .a-text__slim {
    font-size: 14px;
  }
}

.a-text p {
  margin-bottom: 20px;
}

.a-text ul {
  list-style-type: square;
  margin-left: 1.25em;
}

.a-text li {
  margin-bottom: 5px;
}

.a-text a:not(.a-btn) {
  color: #1d1d1b;
}

.a-text > *:first-child {
  margin-top: 0;
}

.a-text > *:last-child {
  margin-bottom: 0;
}

.a-btn, input[type="submit"] {
  display: inline-block;
  height: 40px;
  line-height: 38px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  background: #1d1d1b;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 2px solid #1d1d1b;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  margin-top: 20px;
}

@media print, screen and (min-width: 48em) {
  .a-btn, input[type="submit"] {
    height: 46px;
    line-height: 44px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 23px;
  }
}

@media print, screen and (min-width: 64em) {
  .a-btn, input[type="submit"] {
    font-size: 16px;
  }
}

@media screen and (min-width: 80em) {
  .a-btn, input[type="submit"] {
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }
}

@media screen and (min-width: 80em) {
  .a-btn:hover, input[type="submit"]:hover {
    background-color: #373734;
    border-color: #373734;
  }
}

.a-btn--border, input[type="submit"]--border {
  border: 2px solid #1d1d1b;
  background: transparent;
  color: #1d1d1b;
}

@media screen and (min-width: 80em) {
  .a-btn--border:hover, input[type="submit"]--border:hover {
    background-color: #1d1d1b;
    color: #fff;
  }
}

.a-btn--top, input[type="submit"]--top {
  margin-top: 40px;
}

.a-btn--top-s, input[type="submit"]--top-s {
  margin-top: 20px;
}

.a-link {
  display: inline-block;
  font-weight: 600;
  color: #575756;
  position: relative;
  outline: none;
  margin-bottom: 40px;
  padding-left: 20px;
}

@media screen and (min-width: 80em) {
  .a-link {
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
  }
}

@media screen and (min-width: 80em) {
  .a-link:hover {
    color: #2c2929;
  }
}

.a-link:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
  width: 8px;
  height: 8px;
  border-top: 2px solid #575756;
  border-left: 2px solid #575756;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.a-image img {
  display: inline-block;
  margin: auto;
}

.a-video__container {
  position: relative;
  z-index: 1;
  padding-bottom: 56.25%;
  margin: auto;
}

.a-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f7fb;
}

.m-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px;
}

.m-gallery__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(50% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 48em) {
  .m-gallery__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(33.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.m-gallery__image {
  background: #f6f7fb;
}

.m-gallery img {
  display: block;
  width: 100%;
}

.m-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px;
}

.m-cards__card {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 48em) {
  .m-cards__card {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-align: center;
  }
}

@media print, screen and (min-width: 64em) {
  .m-cards__card {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(33.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 80em) {
  .m-cards__card {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(25% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.m-cards img {
  display: inline-block;
  width: 160px;
  margin-bottom: 20px;
}

.m-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-bottom: -40px;
}

.m-logos__logo {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(50% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  position: relative;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 48em) {
  .m-logos__logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(33.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .m-logos__logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(25% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.m-logos img {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}

.m-logos__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.m-nav-main {
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .m-nav-main__item {
    margin-bottom: 20px;
  }
}

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:last-child {
    margin-bottom: 0;
  }
}

.m-nav-main__link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

@media screen and (min-width: 80em) {
  .m-nav-main__link {
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
  }
}

.m-nav-main__link.is-active {
  font-weight: 600;
}

@media screen and (min-width: 80em) {
  .m-nav-main__link:hover {
    text-decoration: underline;
  }
}

.m-nav-social__item {
  display: inline-block;
  margin-right: 10px;
}

.m-nav-social__item:last-child {
  margin-right: 0;
}

.m-nav-social__logo {
  width: 40px;
  height: 40px;
  fill: #000;
}

@media screen and (min-width: 80em) {
  .m-nav-social__logo {
    -webkit-transition: fill 0.15s;
    transition: fill 0.15s;
  }
}

@media screen and (min-width: 80em) {
  .m-nav-social__logo:hover {
    fill: #333333;
  }
}

.m-nav-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media print, screen and (min-width: 64em) {
  .m-nav-footer {
    display: block;
    text-align: center;
  }
}

.m-nav-footer__item {
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .m-nav-footer__item {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }
}

.m-nav-footer__item:last-child {
  padding-right: 0;
  margin-right: 0;
}

@media print, screen and (min-width: 64em) {
  .m-nav-footer__item:last-child {
    margin-bottom: 0;
  }
}

.m-nav-footer__item:last-child:after {
  display: none;
}

.m-nav-footer__item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 2px;
  background: #000;
}

.m-nav-footer__link {
  font-size: 10px;
  color: #575756;
  text-decoration: none;
}

@media print, screen and (min-width: 64em) {
  .m-nav-footer__link {
    color: #f6f7fb;
  }
}

@media screen and (min-width: 80em) {
  .m-nav-footer__link {
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
  }
}

@media screen and (min-width: 80em) {
  .m-nav-footer__link:hover {
    color: #fff;
  }
}

.m-form label {
  font-size: 13px;
  line-height: 1.3em;
}

@media print, screen and (min-width: 64em) {
  .m-form label {
    font-size: 15px;
  }
}

.m-form .form-item {
  margin-bottom: 20px;
}

.m-form .form-item:last-child {
  margin-bottom: 0;
}

.m-form input[type="text"], .m-form input[type="password"], .m-form input[type="email"], .m-form select, .m-form textarea {
  display: block;
  width: 100%;
  font-family: "Neutra Text", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  border: 1px solid #a3a3a3;
  border-radius: 3px;
  background: #fff;
  color: #000;
  outline: none;
}

@media print, screen and (min-width: 64em) {
  .m-form input[type="text"], .m-form input[type="password"], .m-form input[type="email"], .m-form select, .m-form textarea {
    padding: 10px;
    font-size: 16px;
  }
}

.m-form input[type="checkbox"], .m-form input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 18px;
  height: 18px;
  border: solid 1px #000;
  border-radius: 3px;
  vertical-align: middle;
  margin: 0;
  margin-top: -4px;
  margin-right: 5px;
  background: #fff;
  color: #fff;
  cursor: pointer;
  outline: none;
  position: relative;
}

.m-form input[type="checkbox"]:checked, .m-form input[type="radio"]:checked {
  background: #000;
}

.m-form input[type="checkbox"]:checked:before, .m-form input[type="radio"]:checked:before {
  display: block;
}

.m-form input[type="checkbox"]:before, .m-form input[type="radio"]:before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 12px;
  display: none;
}

.m-form input[type="radio"] {
  border-radius: 50%;
}

.m-form input[type="submit"] {
  font-family: "Neutra Text", sans-serif;
  outline: none;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.m-form .form-item--error-message {
  color: #de3b2f;
  font-size: 14px;
  margin-top: 10px;
}

.m-form .form-item--error-message a {
  color: #000;
}

.m-form .description, .m-form .field-rgpd-text {
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
  line-height: 1.5em;
}

.m-form .description a, .m-form .field-rgpd-text a {
  color: #000;
}

.m-eco__item {
  margin-bottom: 40px;
}

.m-eco__item:last-child {
  margin-bottom: 0;
}

.m-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.m-product__left {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(58.33333% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
}

.m-product__left .a-text {
  display: inline;
}

@media print, screen and (min-width: 64em) {
  .m-product__left {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px;
  }
  .m-product__left .a-text {
    display: none;
  }
}

.m-product__right {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(41.66667% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  text-align: right;
}

.m-product__right .a-text {
  display: none;
}

@media print, screen and (min-width: 64em) {
  .m-product__right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-align: left;
  }
  .m-product__right .a-text {
    display: inline;
  }
}

.m-product__right img {
  width: 100%;
  display: inline-block;
}

.m-product__visual {
  padding-bottom: 100%;
  border-radius: 24px;
  background-size: cover;
}

.m-score {
  position: relative;
  padding-bottom: 40px;
  max-width: 440px;
}

@media print, screen and (min-width: 48em) {
  .m-score {
    margin-top: 40px;
    padding-bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .m-score {
    margin-top: 0;
    max-width: none;
  }
}

.m-score:before {
  content: '';
  position: absolute;
  top: 90%;
  right: 40px;
  bottom: 0;
  left: 40px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(191, 191, 191, .5);
          box-shadow: 0 10px 30px 0 rgba(191, 191, 191, .5);
}

@media print, screen and (min-width: 48em) {
  .m-score:before {
    display: none;
  }
}

.m-score:after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  left: -20px;
  background: #fff;
}

@media print, screen and (min-width: 48em) {
  .m-score:after {
    display: none;
  }
}

.m-score__top {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 64em) {
  .m-score__top {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .m-score__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}

@media print, screen and (min-width: 64em) {
  .m-score__left {
    width: 180px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 0;
  }
}

.m-score__right {
  position: relative;
  z-index: 1;
}

@media print, screen and (min-width: 64em) {
  .m-score__right {
    width: 100%;
  }
}

.m-score__total {
  position: absolute;
  top: -1em;
  right: 0;
  z-index: 1;
  color: #a3a3a3;
}

@media print, screen and (min-width: 64em) {
  .m-score__total {
    display: inline-block;
    position: static;
    text-align: center;
    line-height: 0.7em;
  }
}

.m-score__total--4_2 span {
  color: #ce0e2d;
}

.m-score__total--8_4 span {
  color: #e65300;
}

.m-score__total--12_6 span {
  color: #ffb600;
}

.m-score__total--16_8 span {
  color: #80bc00;
}

.m-score__total--21 span {
  color: #00953b;
}

.m-score__total span {
  display: inline-block;
  vertical-align: sub;
  font-size: 40px;
  font-weight: 700;
  margin-right: 3px;
}

@media print, screen and (min-width: 64em) {
  .m-score__total span {
    display: block;
    font-size: 90px;
    line-height: 1em;
  }
}

.m-score__score {
  position: relative;
  padding-top: 48px;
  max-width: 440px;
}

@media screen and (min-width: 80em) {
  .m-score__score {
    padding-top: 65px;
  }
}

.m-score__bar {
  display: block;
}

.m-score__marker {
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 42px;
}

@media screen and (min-width: 80em) {
  .m-score__marker {
    width: 59px;
  }
}

.m-score__marker img {
  display: block;
}

.m-score__marker span {
  position: absolute;
  top: 12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-weight: 700;
  font-size: 16px;
}

@media screen and (min-width: 80em) {
  .m-score__marker span {
    top: 18px;
    font-size: 20px;
  }
}

.m-details {
  margin-bottom: 40px;
}

.m-details__detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
}

.m-details__detail:last-child {
  margin-bottom: 0;
}

.m-details__detail--1 .m-details__icon {
  background: #80bc00;
}

.m-details__detail--1 .m-details__title {
  color: #80bc00;
}

.m-details__detail--1 .m-details__bar div {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(65%, #80bc00));
  background-image: linear-gradient(to right, #ffffff, #80bc00 65%);
}

.m-details__detail--2 .m-details__icon {
  background: #7c9ac0;
}

.m-details__detail--2 .m-details__title {
  color: #7c9ac0;
}

.m-details__detail--2 .m-details__bar div {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(65%, #7c9ac0));
  background-image: linear-gradient(to right, #ffffff, #7c9ac0 65%);
}

.m-details__detail--3 .m-details__icon {
  background: #ff595a;
}

.m-details__detail--3 .m-details__title {
  color: #ff595a;
}

.m-details__detail--3 .m-details__bar div {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(65%, #ff595a));
  background-image: linear-gradient(to right, #ffffff, #ff595a 65%);
}

.m-details__detail--4 .m-details__icon {
  background: #3f2b56;
}

.m-details__detail--4 .m-details__title {
  color: #3f2b56;
}

.m-details__detail--4 .m-details__bar div {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(65%, #3f2b56));
  background-image: linear-gradient(to right, #ffffff, #3f2b56 65%);
}

.m-details__left {
  width: 38px;
}

.m-details__right {
  padding: 0 20px;
  width: 100%;
}

.m-details__icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: relative;
}

.m-details__icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.m-details__top {
  position: relative;
}

.m-details__title {
  font-weight: 700;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 25px;
  line-height: 1.2em;
}

.m-details__score {
  position: absolute;
  top: 0;
  right: 0;
  color: #575756;
  padding-top: 5px;
}

.m-details__bar {
  height: 3px;
  background: #e6e6e6;
  position: relative;
  overflow: hidden;
}

.m-details__bar div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(65%, red));
  background-image: linear-gradient(to right, #ffffff, red 65%);
}

.m-details__content {
  margin-top: 15px;
  padding-top: 10px;
  position: relative;
}

.m-details__content:nth-child(3) {
  margin-top: 10px;
}

.m-details__content:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #f6f7fb;
}

.m-details__content .accordino__question {
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 700;
  text-decoration: underline;
  color: #575756;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.m-details__content .accordino__question:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 4px;
  height: 4px;
  border-right: 1.5px solid #575756;
  border-bottom: 1.5px solid #575756;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.m-details__content .accordino__question--opened:after {
  -webkit-transform: translateY(4px) rotate(-135deg);
          transform: translateY(4px) rotate(-135deg);
}

.m-details__content .accordino__answer {
  display: none;
  overflow: hidden;
}

.m-details__content .accordino__answer:before {
  content: '';
  display: block;
  height: 10px;
}

.m-details__content .accordino__answer:after {
  content: '';
  display: block;
  height: 10px;
}

.m-cookie-bar {
  padding: 20px;
  max-width: 500px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .15);
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .15);
  border-radius: 2px;
  text-align: center;
  background: #fff;
  position: fixed;
  right: 20px;
  bottom: 80px;
  left: 20px;
  z-index: 2;
}

@media print, screen and (min-width: 48em) {
  .m-cookie-bar {
    right: auto;
    bottom: 20px;
    left: 20px;
  }
}

.m-cookie-bar__img {
  display: inline-block;
  width: 100px;
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .m-cookie-bar__img {
    width: 150px;
  }
}

.m-cookie-bar__buttons {
  margin-top: 20px;
}

.m-cookie-bar__buttons .a-text {
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
}

@media print, screen and (min-width: 64em) {
  .m-cookie-bar__buttons .a-text {
    margin-right: 10px;
  }
}

@media print, screen and (min-width: 64em) {
  .m-cookie-bar__buttons .a-btn {
    margin-left: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.m-cookie-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
  transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
  transition: transform 0s 0.5s, opacity 0s 0.5s;
  transition: transform 0s 0.5s, opacity 0s 0.5s, -webkit-transform 0s 0.5s;
}

.m-cookie-sidebar--shown {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: opacity 0s, -webkit-transform 0s;
  transition: opacity 0s, -webkit-transform 0s;
  transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s, -webkit-transform 0s;
}

.m-cookie-sidebar--shown .m-cookie-sidebar__mask {
  opacity: 1;
}

.m-cookie-sidebar--shown .m-cookie-sidebar__container {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.m-cookie-sidebar__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.m-cookie-sidebar__container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  max-width: 450px;
  background: #fff;
  padding: 40px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.m-cookie-sidebar__switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin: 20px auto 40px;
}

.m-cookie-sidebar__switch--mandatory {
  opacity: 0.5;
  pointer-events: none;
}

.m-cookie-sidebar__option {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(33.33333% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.m-cookie-sidebar__switcher-container {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(33.33333% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  position: relative;
}

.m-cookie-sidebar__switcher {
  display: block;
  width: 38px;
  height: 20px;
  background: #464242;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 10px;
  cursor: pointer;
}

.m-cookie-sidebar__switcher:after {
  content: '';
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 2px 0 0 2px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: left 0.25s;
  transition: left 0.25s;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-cookie-sidebar__switcher[data-value="1"] {
  background: #80bc00;
}

.m-cookie-sidebar__switcher[data-value="1"]:after {
  left: 50%;
}

.o-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

@media print, screen and (min-width: 64em) {
  .o-main {
    height: 100%;
    padding-left: 200px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.o-main__full {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-top: 40px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .o-main__full {
    /*@include xy-cell(5);*/
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 80em) {
  .o-main__full {
    /*@include xy-cell(4);*/
    padding-top: 80px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@media print, screen and (min-width: 64em) {
  .o-main__full:before {
    content: '';
    position: fixed;
    top: 0;
    right: -20px;
    bottom: 0;
    left: 200px;
    background-color: #fff;
    border-radius: 24px 0 0 24px;
  }
}

.o-main__full .a-text {
  text-align: center;
}

.o-main__full .a-heading {
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .o-main__full .a-link {
    position: absolute;
    top: -40px;
    margin: 0;
  }
}

.o-main__left {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-top: 40px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .o-main__left {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(41.66667% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0;
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 80em) {
  .o-main__left {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(33.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-top: 120px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@media print, screen and (min-width: 64em) {
  .o-main__left:before {
    content: '';
    position: fixed;
    top: 0;
    right: -20px;
    bottom: 0;
    left: 200px;
    background-color: #fff;
    border-radius: 24px 0 0 24px;
  }
}

@media print, screen and (min-width: 64em) {
  .o-main__left .a-link {
    position: absolute;
    top: -40px;
    margin: 0;
  }
}

.o-main__right {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .o-main__right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(58.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 80em) {
  .o-main__right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(66.66667% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-top: 80px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.o-main__right:before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  left: -20px;
  background: #f6f7fb;
}

.o-main__right--eco {
  padding-top: 0;
}

@media print, screen and (min-width: 64em) {
  .o-main__right--eco {
    padding-top: 80px;
  }
}

@media screen and (min-width: 80em) {
  .o-main__right--eco {
    padding-top: 80px;
  }
}

.o-main__container {
  position: relative;
  z-index: 1;
}

@media print, screen and (min-width: 64em) {
  .o-main__container--left {
    max-width: 320px;
    margin: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .o-main__container--right {
    max-width: 600px;
    margin: auto;
  }
}

.o-main__splayce {
  display: block;
  width: 71px;
  position: fixed;
  top: 10px;
}

@media print, screen and (min-width: 64em) {
  .o-main__splayce {
    display: block;
    width: auto;
    position: static;
    top: auto;
    max-width: 105px;
    margin-bottom: 30px;
  }
}

.o-main__sub {
  display: block;
  font-size: 12px;
  color: #a3a3a3;
  margin-top: 10px;
  margin-bottom: 25px;
}

.o-main__sub--no-bot {
  margin-bottom: 0;
}

@media print, screen and (min-width: 64em) {
  .o-body--score .o-main__splayce {
    position: absolute;
    top: -95px;
    height: 60px;
  }
}

@media print, screen and (min-width: 64em) {
  .o-header {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    padding: 40px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.o-header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}

@media print, screen and (min-width: 64em) {
  .o-header__container {
    display: block;
    height: auto;
  }
}

.o-header__left {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(50% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
}

@media print, screen and (min-width: 64em) {
  .o-header__left {
    display: none;
  }
}

.o-header__right {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(50% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  text-align: right;
}

@media print, screen and (min-width: 64em) {
  .o-header__right {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

.o-header__splayce {
  display: none;
  width: 71px;
}

.o-header__logo {
  display: inline-block;
}

.o-header__logo img {
  display: block;
  width: 80px;
}

@media print, screen and (min-width: 64em) {
  .o-header__logo img {
    width: 140px;
  }
}

.o-header__nav {
  display: none;
}

@media print, screen and (min-width: 64em) {
  .o-header__nav {
    display: block;
    margin-top: 80px;
  }
}

.o-block {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media print, screen and (min-width: 64em) {
  .o-block {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 80em) {
  .o-block {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.o-block:first-child {
  margin-top: 0;
}

.o-block:last-child {
  margin-bottom: 0;
}

.o-block--padded {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media print, screen and (min-width: 64em) {
  .o-block--padded {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.o-block--primary {
  background-color: #1d1d1b;
}

.o-block--secondary {
  background-color: #2c2929;
}

.o-block--grey {
  background-color: #f6f7fb;
}

.o-block--overflow-hidden {
  overflow: hidden;
}

.o-block--centered {
  text-align: center;
}

.o-block--button {
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .o-block--text-image-left .o-block__left {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}

.o-block__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
}

.o-block__content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 40px;
}

.o-block__content:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 64em) {
  .o-block__content {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 80em) {
  .o-block__content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(83.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .o-block__content--slim {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(83.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 1.25rem);
  }
}

@media screen and (min-width: 80em) {
  .o-block__content--slim {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(66.66667% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(16.66667% + 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .o-block__content--slimer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(66.66667% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(16.66667% + 1.25rem);
  }
}

@media screen and (min-width: 80em) {
  .o-block__content--slimer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(25% + 1.25rem);
  }
}

@media screen and (min-width: 80em) {
  .o-block__content--full {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .o-block__content--top {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(75% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 80em) {
  .o-block__content--top {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(58.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 1.25rem);
  }
}

.o-block__left {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 64em) {
  .o-block__left {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 0;
  }
}

.o-block__right {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
}

@media print, screen and (min-width: 64em) {
  .o-block__right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.o-footer {
  background-color: #f6f7fb;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .o-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 200px;
    padding: 0 20px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: transparent;
  }
}

.o-footer:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 24px 24px 0 0;
  background: #fff;
}

@media print, screen and (min-width: 64em) {
  .o-footer:before {
    display: none;
  }
}

.o-footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
  margin-bottom: -40px;
}

@media print, screen and (min-width: 64em) {
  .o-footer__container {
    margin-bottom: 0;
  }
}

.o-footer__column {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 48em) {
  .o-footer__column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .o-footer__column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(25% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 0;
  }
}
